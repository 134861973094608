'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('index.support', {
                    url: '/support',
                    templateUrl: 'app/support/support.html',
                    controller: 'SupportCtrl'
                });
        }
    ]);